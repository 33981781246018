<template>

  <div class="verify-email rel-container grey lighten-2">
    <v-container fill-height>
    <v-layout fill-height align-center justify-center>

      <v-card>

        <div class="blue darken text-xs-center py-2">
          <span class="headline white--text">Verify Email</span>
        </div>

        <v-card-text>
          <div style="text-align:center;">

            <p>A verification email has been sent to <b class="blue--text text--darken-3">{{email}}</b>.</p>
            <p class="pb-4">Please click the verification link in your email then click below to return to Swipewrap.</p>

            <v-btn color="info" @click="pingModulesAndGoHome">
              <span v-if="!doneButtonProcessing">Done</span>
              <span v-else>
                <v-progress-circular
                  indeterminate
                  :size="28"
                  color="white"
                ></v-progress-circular>
              </span>
            </v-btn>
            <br />
            <span class="grey--text text--lighten-1">Sign in with new account.</span>

          </div>
        </v-card-text>

      </v-card>

    </v-layout>
    </v-container>
  </div>

</template>


<script>
export default {
  name:'verify-email',
  data() {
    return {
      doneButtonProcessing:false,
    }
  },
  computed: {
    email() {
      if( this.$store.getters.user ) { return this.$store.getters.user.email; }
      else{ return null; }
    },
  },
  methods: {

    pingModulesAndGoHome() {
      if(!this.doneButtonProcessing) {
        this.doneButtonProcessing = true;
        return Promise.resolve()
        .then(() => this.$store.dispatch('reloadAuth'))      // updates auth user
        .then(() => this.$router.push('/'))
        .then(() => this.$store.dispatch('clearModules'))
        .then(() => this.$store.dispatch('pingModules', {}))
        .then(() => {
          this.doneButtonProcessing = false;
        })
        .catch((error) => {
          console.log(error.message);
          this.doneButtonProcessing = false;
        });
      }
    },

  }
}
</script>
